export default {
    pageTitle: 'MyBrainMate Site',
    exceptionTitle: `Oopsy! That didn't go well!`,
    exceptionContent: `Something totally unexpected happened and don't worry it's not your fault.`,
    exceptionDetails: `See details`,
    recoveryHeader: 'What to do?',
    recoveryBack: 'You can hit the back button see if the issue resolves itself',
    recoveryRefresh: 'Try to refresh the page if it solves the issue',
    recoveryHome: 'You can go back to Home page and start again',
    recoveryEmail: 'You can also email us and tell us the details of the issue',

    linkBack: 'Back',
    linkRefresh: 'Refresh',
    linkHome: 'Home',
    linkEmail: 'Email Us',
}